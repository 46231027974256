import React from 'react';

function HaafidhTerms() {
  return (
    <div className='privacy'>
        
        <h1 className='termsOfUse-header'>Terms of Use</h1>
        
        <h2 className='privacy-sec_header'>Free Features</h2>
        <p className='privacy-para'>
          Haafidh App provides all its features for free, allowing you to make the most of its functionalities without any cost.
        </p>

        <h2 className='privacy-sec_header'>Supporting the App</h2>
        <p className='privacy-para'>
          If you enjoy using Haafidh and would like to show your support, you have the option to contribute through a monthly subscription or a one-time purchase. While all features remain free, your support helps us continue improving the app and delivering a great user experience.
        </p>

        <h2 className='privacy-sec_header'>Monthly Subscription</h2>
        <p className='privacy-para'>
          By choosing a monthly subscription, you can demonstrate your support on an ongoing basis. Your subscription helps us cover the costs of maintaining and enhancing Haafidh, ensuring a quality experience for all users. You can cancel the subscription at any time.
        </p>

        <h2 className='privacy-sec_header'>One-Time Purchase</h2>
        <p className='privacy-para'>
          Alternatively, you can make a one-time purchase to support Haafidh. This option allows you to make a single contribution and show your appreciation for the app's development and maintenance.
        </p>
        
        <p className='privacy-para'>
          Regardless of whether you choose a monthly subscription or a one-time purchase, we deeply appreciate your support. It encourages us to keep enhancing Haafidh and providing you with a valuable and enjoyable experience.
        </p>
    </div>
  );
}

export default HaafidhTerms;