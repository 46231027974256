import React from 'react'

function SkrybPrivacyPolicy() {
  return (
    <div className='privacy'>
        
        <h1 className='privacy-header '>Privacy Policy</h1>
        <p className='privacy-para'>
            This privacy policy governs your use of the software application Skryb AI (“Application”) that was created by Vaan Corporation("Developer"). The Application lets
            you browse through media from different services based on your preferences and read the summary of selected media which is generated using language models.
        </p>
   
        <h2 className='privacy-sec_header'>No Data Collection</h2>
        <p className='privacy-para'>
            The Application does not collect any information from you. The application requires signing in to different service providers to access content from the respective service provider. You can choose to use the application without signing in and the functionality of the application will be limited.
        </p>

        <h2 className='privacy-sec_header'>Data Source and Rights</h2>
        <p className='privacy-para'>
            The media are sourced from different services based on your preference and the developer does not own those media. The summary is generated by using machine learning models on the device or on the cloud and the developer owns the full rights to them.
        </p>

        <h2 className='privacy-sec_header'>Changes to This Privacy Policy</h2>
        <p className='privacy-para'>
            The developer may update this Privacy Policy from time to time. Thus, you are advised to review this page periodically for 
            any changes. The developer will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately, after 
            they are posted on this page.
        </p>

        <h2 className='privacy-sec_header'>Contact</h2>
        <p className='privacy-para'>
            If you have any questions or suggestions about this Privacy Policy, kindly send an email to <a href = "mailto: contact@vaancorp.com" className='privacy-mail'>contact@vaancorp.com</a> with the subject line "Skryb AI Privacy Policy".
        </p>
    </div>
  )
}

export default SkrybPrivacyPolicy