import React from 'react'

function Support() {
  return (
    <div className='privacy'> 
        <h1 className='privacy-header '>Support</h1>
        <p className='privacy-para'>For any help with our products, you can always reach using the following contact info or via our social media accounts.</p>
   
        <h2 className='privacy-sec_header'>Email</h2>
        <p className='privacy-para'><a href = "mailto:contact@vaancorp.com" className='privacy-mail'>contact@vaancorp.com</a></p>

        <h2 className='privacy-sec_header'>Mobile</h2>
        <p className='privacy-para'>+91 81421 51430.</p>

        <h2 className='privacy-sec_header'>Office Locations</h2>
        <p className='privacy-para'>Tirunelveli, Tamil Nadu, India.<br/>Houston, Texas, US.</p>
    </div>
  )
}

export default Support