import React from 'react'

function CoutDownPrivacyPolicy() {
  return (
    <div className='privacy'>
        
        <h1 className='privacy-header '>Privacy Policy</h1>
        <p className='privacy-para'>This privacy policy governs your use of the software application Countdown (“Application”) for Apple devices (iPhone and iPad) that was created by Mohammed Imthathullah M("Developer"). The 
                Application lists events in your calendar and shows the Countdown for those events.</p>
   
        <h2 className='privacy-sec_header'>No Data Collection</h2>
        <p className='privacy-para'>The Application does not collect any information from you. Internet connection is not required to use this application.</p>

        <h2 className='privacy-sec_header'>Data Source</h2>
        <p className='privacy-para'>The data displayed in the application is from the Calendar in your device. The application lets you view, add, edit or delete events in your Calendar.</p>

        <h2 className='privacy-sec_header'>Changes to This Privacy Policy</h2>
        <p className='privacy-para'>The developer may update this Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. The developer will notify you of any changes by 
        posting the new Privacy Policy on this page. These changes are effective immediately, after they are posted on this page.</p>

        <h2 className='privacy-sec_header'>Contact</h2>
        <p className='privacy-para'>If you have any questions or suggestions about this Privacy Policy, kindly send an email to <a href = "mailto: contact@vaancorp.com" className='privacy-mail'>contact@vaancorp.com</a> with the subject line "Countdown iOS App Privacy Policy".</p>
    </div>
  )
}

export default CoutDownPrivacyPolicy