import React from 'react'

function SkrybTerms() {
  return (
    <div className='privacy'>
        
        <h1 className='termsOfUse-header '>Terms of Use</h1>
        
        <h2 className='privacy-sec_header'>Free trial</h2>
        <p className='privacy-para'>
        Skryb AI provides a free version with limited features which you can access inside the app.
        </p>

        <h2 className='privacy-sec_header'>Subscription</h2>
        <p className='privacy-para'>
          You can unlock all Skryb AI features by purchasing a monthly or yearly subscription, which are available inside the app in all platforms. The subscription renews automatically at the end
          of each billing period and can be canceled any time.
        </p>
    </div>
  )
}

export default SkrybTerms