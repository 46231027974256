import React from 'react'

function Cancellation() {
  return (
    <div className='privacy'> 
        <h2 className='privacy-sec_header'>Help us improve the product</h2>
        <p className='privacy-para'>Reach out to us at contact@vaancorp.com with your frustrations, we'll address them if possible or let you know if that is not in our plans so that you can proceed with cancellation and/or refund.</p>

        <h2 className='privacy-sec_header'>Cancellation policy</h2>
        <p className='privacy-para'>All the subscriptions within our products can be cancelled via the same platform you purchased it. Cancellations should be done before 24 hours of the next billing date.</p>
   
        <h2 className='privacy-sec_header'>Refund policy</h2>
        <p className='privacy-para'>If you are not satisfied with any of our products, you can request a refund by mailing to contact@vaancorp.com with subject Refund Request: [Product Name] or via the payment processor in each platform. If you request a refund within 24 hours of purchasing a product without abusing it, your request will be approved without any further questions. Any requests beyond that will be processed on a case by case basis.</p>
    </div>
  )
}

export default Cancellation